import React from 'react';
import Layout from '../views/Layout'
import Registerboard from '../views/registerboard';
const Register = () => {
    return (
        <div>
            <Layout>
                <Registerboard></Registerboard>
            </Layout>
        </div>
    );
}

export default Register;
