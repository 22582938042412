import React, { useEffect, useState } from 'react';

const Bottom = ({ isColorMode, onToggleColorMode }) => {
    const author = "Made by 陳威皓"; // Replace with the actual author name
    const version = "1.0.0"; // Replace with the actual version number
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');

    useEffect(() => {
        const handleThemeChange = () => {
            setDarkMode(localStorage.getItem('theme') === 'true');
        };

        // Subscribe to the theme change event
        document.addEventListener('themeChange', handleThemeChange);

        return () => {
            // Unsubscribe when the component is unmounted
            document.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    const backgroundColor = isDarkMode ? '#1f2937' : '#f3f4f6';


    return (
        <div style={{marginTop:'5%'}}    className={`bottom-bar p-4 text-center  fixed bottom-0 w-full z-0`}>
            <div className={`hidden sm:inline sm:float-left text-gray-500`}>{author}</div>
            <div className={`hidden sm:inline sm:float-right text-gray-500`}>Version {version}</div>
        </div>
    );
};

export default Bottom;