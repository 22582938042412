// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    transition: background-color 0.3s ease-in-out;
    
}

.dark-theme {
    /* 根據 dark mode 的樣式設定 */
    background-color: #1f2937;
    color: #dcdcdc;
}

.light-theme {
    /* 根據 light mode 的樣式設定 */
    background-color: #f3f4f6;
    color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/views/css/post.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;;AAEjD;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["*{\r\n    transition: background-color 0.3s ease-in-out;\r\n    \r\n}\r\n\r\n.dark-theme {\r\n    /* 根據 dark mode 的樣式設定 */\r\n    background-color: #1f2937;\r\n    color: #dcdcdc;\r\n}\r\n\r\n.light-theme {\r\n    /* 根據 light mode 的樣式設定 */\r\n    background-color: #f3f4f6;\r\n    color: #333333;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
