// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 10%;
    border-bottom: #fff solid 2px;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .profile-picture {
    width: 75px;
    height: 75px;
    margin-bottom: 5%;
    border-radius: 50%;
  }
  
  .profile-info {
    margin-left: 20px;
  }
  
  .profile-info h1 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .profile-info p {
    font-size: 16px;
    margin-bottom: 0;
  }
  
  .nav {
    display: flex;
    align-items: center;
  }
  
  .nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .nav ul li a {
    text-decoration: none;
    color: #000;
  }

`, "",{"version":3,"sources":["webpack://./src/views/test.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb","sourcesContent":["\r\n.container {\r\n    width: 100%;\r\n    max-width: 960px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    margin-bottom: 10%;\r\n    border-bottom: #fff solid 2px;\r\n  }\r\n  \r\n  .header {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .profile-picture {\r\n    width: 75px;\r\n    height: 75px;\r\n    margin-bottom: 5%;\r\n    border-radius: 50%;\r\n  }\r\n  \r\n  .profile-info {\r\n    margin-left: 20px;\r\n  }\r\n  \r\n  .profile-info h1 {\r\n    font-size: 24px;\r\n    margin-bottom: 5px;\r\n  }\r\n  \r\n  .profile-info p {\r\n    font-size: 16px;\r\n    margin-bottom: 0;\r\n  }\r\n  \r\n  .nav {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .nav ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n  }\r\n  \r\n  .nav ul li {\r\n    display: inline-block;\r\n    margin-right: 20px;\r\n  }\r\n  \r\n  .nav ul li a {\r\n    text-decoration: none;\r\n    color: #000;\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
