import React, { useState, useEffect } from 'react';
import Layout from '../views/Layout';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../views/css/test.css'
const Setting = () => {
    const [userID, setUserID] = useState('');
    const [ErrorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [avatarURL, setAvatarURL] = useState('');
    const [password, setPassword] = useState('');
    const [bio, setBio] = useState('');
    const [username, setUsername] = useState('');
    const [newBio, setNewBio] = useState('');
    const [old_username, setOldUsername] = useState('');
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');
    const [RE_password, setRE_password] = useState('')
    useEffect(() => {
        const handleThemeChange = () => {
            setDarkMode(localStorage.getItem('theme') === 'true');
        };

        // Subscribe to the theme change event
        document.addEventListener('themeChange', handleThemeChange);

        return () => {
            // Unsubscribe when the component is unmounted
            document.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);
    const error_toast = (message) =>
        toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
        });

    const success_toast = (message) =>
        toast.success(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
        });


    const fetchUserBio = async (userID) => {
        try {
            const response = await axios.get(`https://backend.itsanonyanony.com/api/user/${userID}/bio`);
            if (response.data.bio) {
                setBio(response.data.bio);
            }
        } catch (error) {
            console.error('獲取個人介紹時發生錯誤:', error);
        }
    };




    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
                    if (response.data.data === 'success') {
                        setIsLoggedIn(true);
                        setUserID(response.data.user.UserID);
                        setOldUsername(response.data.user.username);
                        getUserAvatar(response.data.user.UserID);
                        fetchUserBio(response.data.user.UserID);
                    } else {
                        setIsLoggedIn(false);
                    }
                } catch (error) {
                    console.error('驗證令牌時發生錯誤:', error);
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
        };

        fetchUserData();
    }, []);

    const getUserAvatar = async (UserID) => {
        try {
            const backendURL = `https://backend.itsanonyanony.com/api/getUserAvatar?ID=${UserID}`;
            const response = await axios.get(backendURL);

            if (response.status === 200) {
                setAvatarURL(response.data.avatar);
                setErrorMessage('');
            } else {
                setAvatarURL('');
                setErrorMessage('找不到使用者的頭像');
            }
        } catch (error) {
            console.error('發生錯誤：', error);
            setAvatarURL('');
            setErrorMessage('發生錯誤');
        }
    };

    const handleAvatarChange = async (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('avatar', selectedFile);

        try {
            const response = await fetch(`https://backend.itsanonyanony.com/api/user/${userID}/Cavatar`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                success_toast('頭像上傳成功!');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                error_toast('頭像上傳失敗');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSaveChanges = async () => {
        if (!userID) return error_toast('尚未登入');

        try {
            if (username.trim() !== '') {
                const response = await axios.post('https://backend.itsanonyanony.com/api/change-username', {
                    userID: userID,
                    newUsername: username,
                });

                if (response.data.message === 'done') {
                    success_toast('使用者名稱更改成功');
                } else if (response.data.message === 'replace') {
                    error_toast('使用者名稱已存在，請嘗試其他使用者名稱');
                } else {
                    error_toast('更改使用者名稱時發生錯誤');
                }
            }

            if (password) {
                if (password !== RE_password) {
                    error_toast('密碼不一致!')
                } else {
                    const response = await fetch('https://backend.itsanonyanony.com/api/changePassword', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userID, password }),
                    });

                    if (response.ok) {
                        success_toast('密碼已成功更改');
                    } else {
                        error_toast('無法更改密碼');
                    }
                }


            }

            if (newBio) {
                const response = await axios.post('https://backend.itsanonyanony.com/api/update-bio', {
                    userID: userID,
                    bio: newBio,
                });

                if (response.data.message === 'done') {
                    success_toast('自我介绍已更新');
                } else {
                    error_toast('請求時發生了錯誤');
                }
            }
        } catch (error) {
            console.error('請求時發生錯誤:', error);
            error_toast('請求時發生了錯誤');
        }
    };

    return (
        <div>
            <Layout>
                <ToastContainer />
                <div className="flex flex-col lg:flex-row">
                    {/* Left Section */}
                    <div className="lg:w-1/3 p-8 flex flex-col items-center">
                        {/* Avatar Section */}
                        <div className="group relative flex flex-col items-center">
                            <img className="avatar rounded-full border-4 border-white" src={avatarURL} alt="Avatar" />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-50 text-white transition-opacity duration-300 group-hover:opacity-100 cursor-pointer">
                                <label htmlFor="avatarUpload">
                                    更換頭像
                                </label>
                                <i className="fas fa-camera text-sm ml-1"></i>
                            </div>
                            <input
                                id="avatarUpload"
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={handleAvatarChange}
                            />
                        </div>



                        {/* Bio Section */}
                        <textarea
                            id={`${isDarkMode ? 'dark-theme' : 'light-theme'}`}
                            className="bio mt-8 h-48 rounded-md border-2 border-gray-300 p-2 w-full"
                            value={bio}
                            onChange={(e) => {
                                // Replace newline characters with <br> and update state
                                const bioWithLineBreaks = e.target.value.replace(/\n/g, '<br>');
                                setBio(bioWithLineBreaks);
                                setNewBio(bioWithLineBreaks);
                            }}
                            onKeyDown={(e) => {
                                // Handle Enter key press to insert line break
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const cursorPos = e.target.selectionStart;
                                    const newBio = `${bio.slice(0, cursorPos)}<br>${bio.slice(cursorPos)}`;
                                    setBio(newBio);
                                    setNewBio(newBio);
                                }
                            }}
                        ></textarea>

                    </div>

                    {/* Right Section */}
                    <div className="lg:w-2/3 p-8 flex flex-col items-center">
                        {/* Password Inputs */}
                        <input
                            type="password"
                            className="password w-full lg:w-3/4 border-2 border-gray-300 p-2 mt-8 mb-4"
                            id={`${isDarkMode ? 'dark-theme' : 'light-theme'}`}
                            placeholder="Enter new password"
                            onChange={(e) => {
                                setPassword(e.target.value);

                            }}
                        />
                        <input
                            type="password"
                            className="password w-full lg:w-3/4 border-2 border-gray-300 p-2 mb-8"
                            id={`${isDarkMode ? 'dark-theme' : 'light-theme'}`}
                            placeholder="Confirm new password"
                            onChange={(e) => {
                                setRE_password(e.target.value);

                            }}
                        />

                        {/* Save Button */}
                        <button
                            type="button"
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={handleSaveChanges}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </Layout >
        </div >



    );
};

export default Setting;
