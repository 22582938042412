import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../views/Layout'
import 'md-editor-rt/lib/preview.css';
import { ToastContainer, toast } from 'react-toastify';
import { MdPreview, MdCatalog } from 'md-editor-rt';
import 'md-editor-rt/lib/preview.css';
import 'react-toastify/dist/ReactToastify.css';
import '../views/css/post.css'
import { createMemoryRouter } from 'react-router-dom';
const Post = () => {
    const params = new URLSearchParams(window.location.search);
    const [postid, setPostid] = useState(params.get('postID'));
    const [posts, setPosts] = useState();
    const [ErrorMessage, setErrorMessage] = useState('似乎出了點問題...請檢查是否有這篇貼文');
    const [post_title, setPost_title] = useState('');
    const [post_author, setPost_author] = useState('');
    const [post_userID, setPost_UserID] = useState('');
    const [post_date, setPost_date] = useState('');
    const [html, setHtml] = useState('');
    const [id] = useState('preview-only');
    const [noPosts, setNoPosts] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [comments, setComments] = useState(['1', '2']);
    const [newComment, setNewComment] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [User, setUser] = useState(''); // 初始化为空字符串
    const [userID, setUserID] = useState('');
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');
    // 准备成功和错误的 Toast 函数
    const done_toast = () => toast.success('成功發送!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const error_toast = () => toast.error('發送失敗!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const fetchComments = async () => {
        try {
            const response = await axios.get(`https://backend.itsanonyanony.com/api/comments?postID=${postid}`);
            const commentsWithAvatar = await Promise.all(
                response.data.map(async (comment) => {
                    // Format the comment date
                    const commentDate = new Date(comment.date);
                    const formattedDate = `${commentDate.getFullYear()}/${(commentDate.getMonth() + 1).toString().padStart(2, '0')}/${commentDate.getDate().toString().padStart(2, '0')} ${commentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;

                    // Get the commenter's avatar
                    const avatarResponse = await axios.get(`https://backend.itsanonyanony.com/api/getUserAvatar?ID=${comment.userID}`);
                    comment.avatar = avatarResponse.data.avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png';
                    comment.formattedDate = formattedDate;

                    return comment;
                })
            );

            commentsWithAvatar.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);

                return dateB - dateA;
            });

            const formattedComments = commentsWithAvatar.map(comment => {
                // Convert the date to the desired format
                const formattedDate = new Date(comment.date).toLocaleString('zh-TW', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                });

                return {
                    ...comment,
                    formattedDate: formattedDate,
                };
            });

            console.log('Sorted and Formatted Comments:', formattedComments);

            setComments(formattedComments);
        } catch (error) {
            console.error('獲取留言失敗:', error);
        }
    };



    const handleSubmitComment = async () => {
        try {
            await axios.post(`https://backend.itsanonyanony.com/api/comments`, {
                postID: postid,
                username: User.username,
                text: newComment,
                userID: userID,
                avatar: User.avatar
            });
            setNewComment('');
            fetchComments(); // Refresh the comments list
            done_toast('發布成功!');
        } catch (error) {
            console.error('發布留言失敗:', error);
            error_toast('發布留言失敗');
        }
    };
    const goBack = async () => {
        window.history.back(1)
    }
    useEffect(() => {
        const handleThemeChange = () => {
            setDarkMode(localStorage.getItem('theme') === 'true');
        };

        // 訂閱主題變更事件
        document.addEventListener('themeChange', handleThemeChange);

        return () => {
            // 清除訂閱
            document.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);
    useEffect(() => {
        const fetchUserData = async () => {
            axios.get(`https://backend.itsanonyanony.com/api/posts?ID=${postid}`)
                .then((response) => {
                    if (response.data === null) {
                        setNoPosts(true);
                    } else {
                        setPosts(response.data);
                        setHtml(response.data.content);
                        setPost_author(response.data.author);
                        setPost_UserID(response.data.userID)
                        setPost_date(response.data.date);
                        setPost_title(response.data.title);
                        setNoPosts(false);
                    }
                })
                .catch((error) => {
                    console.error('獲取貼文失敗:', error);
                });
        };

        fetchUserData();
    }, [postid]);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
                    if (response.data.data === 'success') {
                        setIsLoggedIn(true);
                        setUserID(response.data.user.UserID);
                        setUser(response.data.user); // 更新User为用户对象

                    } else {
                        setIsLoggedIn(false);
                        console.log('err');
                    }
                } catch (error) {
                    console.error('驗證令牌时发生错误:', error);
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
        };

        // 调用异步函数以获取用户信息
        fetchUserData();
    }, []);
    useEffect(() => {
        fetchComments()
    }, [postid]);
    useEffect(() => {
        const fetchUserAvatar = async () => {
            try {
                const response = await axios.get(`https://backend.itsanonyanony.com/api/getUserAvatar?ID=${posts.userID}`);
                if (response.data.avatar) {
                    setAvatar(response.data.avatar);
                    console.log('完成');
                } else {
                    setAvatar('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
                    console.log('預設');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserAvatar();
    }, [post_author]);


    const containerStyle = {
        backgroundColor: isDarkMode ? '#1f2937' : '#f3f4f6',
        transition: 'background-color 0.3s ease-in-out',
    };
    return (
        <Layout>
            <ToastContainer></ToastContainer>

            <div class="p-5">
                <div class=" items-baseline flex-wrap">
                    <div class="flex m-2">
                        <button onClick={goBack} class="text-base  rounded-r-none  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
        hover:bg-gray-200  
        bg-gray-100 
        text-gray-700 
        border duration-200 ease-in-out 
        border-gray-600 transition">
                            <div class="flex leading-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left w-5 h-5">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                                Back</div>
                        </button>

                    </div>
                </div>

            </div>

            <div className="max-w-3xl mx-auto mt-8 p-4">
                {/* 大標題 */}
                <h1 style={{ fontSize: '42px' }} className={`text-3xl font-bold mb-4  ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>{post_title}</h1>

                {/* 貼文發表者資訊 */}
                <div className="flex items-center space-x-4 mb-4" style={{ borderBottom: '1px solid white' }}>
                    <img
                        src={avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png'}
                        alt="Avatar"
                        className="w-8 h-8 rounded-full"
                    />
                    <div>
                        <a href={`http://198.98.61.231:3000/user-profile?userID=${post_userID}`} className={`text-sm text-gray-600 ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>{post_author}</a>
                        <p style={{fontSize:'1xp'}} className={`text-base ${isDarkMode ? 'text-blue' : 'text-gray-700'}`}>日期: {new Date(post_date).toLocaleString('zh-TW', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        })}</p>

                    </div>
                </div>

                {/* 貼文內容 */}
                <MdPreview style={containerStyle} editorId={id} modelValue={html} theme={`${isDarkMode ? 'dark' : 'light'}`} previewTheme='github' className={`${isDarkMode ? 'dark-theme' : 'light-theme'}`} />


                {/* 留言區域 */}
                {isLoggedIn ? (
                    <div className="mt-8" style={{ marginBottom: '5%', zIndex: '999' }}>
                        <textarea
                            className="w-full p-2 border border-gray-300 rounded"
                            rows="3"
                            placeholder="發表留言..."
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <button
                            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 z-50"
                            onClick={handleSubmitComment}
                        >
                            發送留言
                        </button>



                    </div>

                ) : (
                    <p className="mt-8 text-gray-600">
                        登入後即可發表留言，<a href="/login" className="text-blue-500 hover:underline">點擊此處登入</a>。
                    </p>
                )}
                {comments.map((comment) => (
                    <div key={comment.commentID} className={`mb-4 p-4 rounded ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>
                        <div className="flex items-center space-x-4">
                            <img
                                src={comment.avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png'}
                                alt="Avatar"
                                className="w-8 h-8 rounded-full"
                            />
                            <div>
                                <a href={`http://198.98.61.231:3000/user-profile?userID=${comment.userID}`} style={{ fontSize: '16px' }} className={`text-sm text-gray-600 font-bold ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>{comment.username}</a>
                                <p className={`text-xs text-gray-500 ${isDarkMode ? 'dark' : 'light'}`}>{comment.formattedDate}</p>
                            </div>
                        </div>
                        <p style={{ fontSize: '16px' }} className={`text-xs text-gray-500 mt-2 ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>{comment.content}</p>
                    </div>
                ))}
            </div>

        </Layout>

    );
}

export default Post;
