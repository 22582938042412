// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 10%;
    background: #333333;
    border: 2px solid goldenrod;
    border-radius: 4px;
    color: black;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    padding: 10px 20px;
}

.btn span {
    color: black;
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: goldenrod;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
}

.btn:hover,
.btn:focus {
    color: black;
}

.btn:hover span {
    transform: translateY(0) scale(2);
}

.btn span:nth-child(1) {
    --n: 1;
}

.btn span:nth-child(2) {
    --n: 2;
}

.btn span:nth-child(3) {
    --n: 3;
}

.btn span:nth-child(4) {
    --n: 4;
}
.btn span:nth-child(5) {
    --n: 5;
}
.btn span:nth-child(6) {
    --n: 6;
}
.btn span:nth-child(7) {
    --n: 7;
}
.btn span:nth-child(8) {
    --n: 8;
}
`, "",{"version":3,"sources":["webpack://./src/views/css/button2.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,2BAA2B;IAC3B,2BAA2B;IAC3B,kBAAkB;IAClB,gCAAgC;IAChC,gBAAgB;IAChB,6CAA6C;IAC7C,WAAW;AACf;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,MAAM;AACV;;AAEA;IACI,MAAM;AACV;AACA;IACI,MAAM;AACV;AACA;IACI,MAAM;AACV;AACA;IACI,MAAM;AACV;AACA;IACI,MAAM;AACV","sourcesContent":[".btn {\n    display: inline-block;\n    position: relative;\n    z-index: 1;\n    width: 10%;\n    background: #333333;\n    border: 2px solid goldenrod;\n    border-radius: 4px;\n    color: black;\n    font-size: 1rem;\n    text-transform: uppercase;\n    font-weight: bold;\n    text-align: center;\n    text-decoration: none;\n    overflow: hidden;\n    transition: 0.5s;\n    padding: 10px 20px;\n}\n\n.btn span {\n    color: black;\n    position: absolute;\n    width: 25%;\n    height: 100%;\n    background-color: goldenrod;\n    transform: translateY(150%);\n    border-radius: 50%;\n    left: calc((var(--n) - 1) * 25%);\n    transition: 0.5s;\n    transition-delay: calc((var(--n) - 1) * 0.1s);\n    z-index: -1;\n}\n\n.btn:hover,\n.btn:focus {\n    color: black;\n}\n\n.btn:hover span {\n    transform: translateY(0) scale(2);\n}\n\n.btn span:nth-child(1) {\n    --n: 1;\n}\n\n.btn span:nth-child(2) {\n    --n: 2;\n}\n\n.btn span:nth-child(3) {\n    --n: 3;\n}\n\n.btn span:nth-child(4) {\n    --n: 4;\n}\n.btn span:nth-child(5) {\n    --n: 5;\n}\n.btn span:nth-child(6) {\n    --n: 6;\n}\n.btn span:nth-child(7) {\n    --n: 7;\n}\n.btn span:nth-child(8) {\n    --n: 8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
