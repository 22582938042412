import { MdEditor, config } from "md-editor-rt";
import "md-editor-rt/lib/style.css";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import Layout from '../views/Layout';
import { ToastContainer, toast } from 'react-toastify';

import { ExportPDF } from '@vavt/rt-extension';
import { Emoji } from '@vavt/rt-extension';
import '@vavt/rt-extension/lib/asset/style.css';
const Createpost = () => {
    config({
        editorConfig: {
            languageUserDefined: {
                'my-lang': {
                    toolbarTips: {
                        bold: '加粗',
                        underline: '下劃線',
                        italic: '斜體',
                        strikeThrough: '刪除線',
                        title: '標題',
                        sub: '下標',
                        sup: '上標',
                        quote: '引用',
                        unorderedList: '無序列表',
                        orderedList: '有序列表',
                        task: '任務列表',
                        codeRow: '行內程式碼',
                        code: '區塊程式碼',
                        link: '鏈接',
                        image: '圖片',
                        table: '表格',
                        mermaid: 'mermaid圖',
                        katex: 'katex公式',
                        revoke: '後退',
                        next: '前進',
                        save: '保存',
                        prettier: '美化',
                        pageFullscreen: '瀏覽器全屏',
                        fullscreen: '屏幕全屏',
                        preview: '預覽',
                        htmlPreview: 'html代碼預覽',
                        catalog: '目錄',
                        github: '源碼地址'
                    },
                    titleItem: {
                        h1: '一級標題',
                        h2: '二級標題',
                        h3: '三級標題',
                        h4: '四級標題',
                        h5: '五級標題',
                        h6: '六級標題'
                    },
                    imgTitleItem: {
                        link: '添加鏈接',
                        upload: '上傳圖片',
                        clip2upload: '裁剪上傳'
                    },
                    linkModalTips: {
                        linkTitle: '添加鏈接',
                        imageTitle: '添加圖片',
                        descLabel: '鏈接描述：',
                        descLabelPlaceHolder: '請輸入描述...',
                        urlLabel: '鏈接地址：',
                        urlLabelPlaceHolder: '請輸入鏈接...',
                        buttonOK: '確定'
                    },
                    clipModalTips: {
                        title: '裁剪圖片上傳',
                        buttonUpload: '上傳'
                    },
                    copyCode: {
                        text: '複製程式碼',
                        successTips: '已複製！',
                        failTips: '複製失敗！'
                    },
                    mermaid: {
                        flow: '流程圖',
                        sequence: '時序圖',
                        gantt: '甘特圖',
                        class: '類圖',
                        state: '狀態圖',
                        pie: '餅圖',
                        relationship: '關係圖',
                        journey: '旅程圖'
                    },
                    katex: {
                        inline: '行內公式',
                        block: '區塊公式'
                    },
                    footer: {
                        markdownTotal: '字數',
                        scrollAuto: '同步滾動'
                    }
                }
            }
        }
    });
    const params = new URLSearchParams(window.location.search);
    const [editorID, seteditorID] = useState(params.get('editorID'));
    const [userID, setUserID] = useState('');
    const [ErrorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [User, setUser] = useState(''); // 初始化为空字符串
    const [avatarURL, setAvatarURL] = useState('');
    const [text, setText] = useState("嗨!");
    const [html, setHtml] = useState("");
    const [ImgMessage, setImgMessage] = useState('上傳縮略圖')
    const [thumbnailUrl, setthumbnailUrl] = useState('')
    const [Nopost, setNopost] = useState(true)
    const [createbutton, setcreatebutton] = useState('創建貼文')
    const [caneditor, setcaneditor] = useState(false)
    const error_toast = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const warn_toast = (message) => toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const success_toast = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const veriftcanedtior = async () => {
        if (User.isAdmin || editorID.includes(User.postsID)) {
            setcaneditor(true)
            console.log('can')
            return true
        } else {
            setcaneditor(false)
            console.log('cant')
            return false
        }
    }
    useEffect(() => {

        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
                    if (response.data.data === 'success') {
                        setIsLoggedIn(true);
                        setUserID(response.data.user.UserID);
                        setUser(response.data.user); // 更新User为用户对象

                        getUserAvatar(response.data.user.UserID);
                    } else {
                        setIsLoggedIn(false);
                        console.log('err');
                    }
                } catch (error) {
                    console.error('驗證令牌时发生错误:', error);
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
        };

        // 调用异步函数以获取用户信息
        fetchUserData();
    }, []);
    //獲取投向
    const getUserAvatar = async (UserID) => {
        try {
            const backendURL = `https://backend.itsanonyanony.com/api/user/${UserID}/avatar`;
            const response = await axios.get(backendURL, { responseType: 'blob' });

            if (response.status === 200) {
                const blob = response.data;
                const url = URL.createObjectURL(blob);
                setAvatarURL(url);
                setErrorMessage('');
            } else {
                setAvatarURL('');
                setErrorMessage('找不到使用者的頭像');
            }
        } catch (error) {
            console.error('發生錯誤：', error);
            setAvatarURL('');
            setErrorMessage('發生錯誤');
        }
    };

    const post = async () => {
        if (!Nopost) {
            const can = await veriftcanedtior()
            console.log(can)
            if (can) {
                try {
                    const updateData = {
                        postID: editorID,
                        content: text
                    }
                    await axios.post('http://backend.itsanonyanony.com/api/updatecontent', updateData)
                    success_toast('更新完成!')
                    
                    setTimeout(() => {
                        window.location.href = `/`
                    }, 2000);
                    

                } catch (err) {
                    error_toast('更新貼文時出了問題')
                }
            }else{
                error_toast('你沒權限來更改這篇文章')
            }


        } else {
            if (!title) {
                error_toast('你沒有輸入標題');
                return;
            }
            if (!thumbnailUrl) setthumbnailUrl('http://198.98.61.231:3001/uploads/no_img.png')
            if (isLoggedIn) {
                const tagsArray = tag.split(',' || '，').map((t) => t.trim()); // 將標籤字串拆分成標籤陣列
                const postData = {
                    title: title,
                    tag: tagsArray, // 儲存標籤陣列
                    author: User.username, // 請將其替換為實際的使用者名稱
                    content: text,
                    thumbnail: thumbnailUrl,
                    userID: userID
                };

                await axios.post('https://backend.itsanonyanony.com/api/create-post', postData);
                window.location.href = '/';
            } else {
                error_toast('你必須先登入帳號！');
            }
        }


    }
    const fectpostData = async () => {
        if (editorID) {

            const fetchUserData = async () => {
                axios.get(`https://backend.itsanonyanony.com/api/posts?ID=${editorID}`)
                    .then((response) => {
                        if (response.data === null) {
                            setText(`未知的文章ID`)
                            setNopost(true)
                        } else {
                            setText(response.data.content)
                            setcreatebutton(`更新貼文`)
                            setNopost(false)
                        }
                    })
                    .catch((error) => {
                        console.error('獲取貼文失敗:', error);
                    });
            };

            fetchUserData();

        }
    }



    //上傳圖片
    const onUploadImg = async (files, callback) => {
        const res = await Promise.all(
            files.map((file) => {
                return new Promise((rev, rej) => {
                    const form = new FormData();
                    form.append('file', file);

                    axios
                        .post('https://backend.itsanonyanony.com/api/img/upload', form, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })

                        .then((res) => rev(res))
                        .catch((error) => rej(error));
                });
            })
        );

        callback(res.map((item) => item.data.urls));
    };
    const [language] = useState('my-lang');

    const [tag, setTag] = useState('')
    const [title, setTitle] = useState('')
    //上傳縮略圖
    const handleImageUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            warn_toast('上傳中...')
            const formData = new FormData();
            formData.append('file', file);

            axios
                .post('https://backend.itsanonyanony.com/api/img/upload/thumbnail', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {

                    success_toast('上傳成功');
                    setthumbnailUrl(response.data.urls)
                })
                .catch((error) => {
                    // 处理上传失败后的逻辑
                    console.error('上傳失敗', error);
                });
        }
    };

    return (
        <div onLoad={fectpostData}>
            <ToastContainer></ToastContainer>
            <Layout>

                {isLoggedIn ? (
                    <>
                        <MdEditor
                            className="MdEditor"
                            modelValue={text}
                            onChange={(modelValue) => {
                                setText(modelValue);
                            }}
                            onHtmlChanged={(html) => {
                                setHtml(html);
                            }}
                            language={language}
                            theme="dark"
                            onUploadImg={onUploadImg}
                            toolbars={[
                                'bold',
                                'underline',
                                'italic',
                                '-',
                                'strikeThrough',
                                'sub',
                                'sup',
                                'quote',
                                'unorderedList',
                                'orderedList',
                                'task',
                                '-',
                                'codeRow',
                                'code',
                                'link',
                                'image',
                                'table',
                                'mermaid',
                                'katex',
                                '-',
                                'revoke',
                                'next',
                                'save',
                                0,
                                1,
                                '=',
                                'pageFullscreen',
                                'fullscreen',
                                'preview',
                                'htmlPreview',
                                'catalog',
                                'github'
                            ]}
                            defToolbars={[<ExportPDF key="ExportPDF" modelValue={text} />, <Emoji key="Emoji" trigger={<span>😊</span>} />]}
                        />
                        <div className="button-container flex flex-col items-center justify-center">
                            {Nopost ? (<><input
                                type="text"
                                placeholder="輸入標題"
                                value={title}
                                className="TextInput mb-4 p-2 border border-gray-300 rounded"
                                onChange={(e) => setTitle(e.target.value)}

                            />
                                <input
                                    type="text"
                                    placeholder="輸入標籤(使用逗點隔開)"
                                    value={tag}
                                    className="TextInput mb-4 p-2 border border-gray-300 rounded"
                                    onChange={(e) => setTag(e.target.value)}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <label
                                    htmlFor="fileInput"
                                    className="upload-btn p-2 bg-blue-500 text-white rounded cursor-pointer"
                                >
                                    上傳縮略圖
                                </label></>) : (<></>)}
                            <button
                                onClick={post}
                                className="btn from-left bg-green-500 text-white px-4 py-2 rounded"
                            >
                                {createbutton}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="errorMessage">
                            你必須先登入才可以發表貼文
                        </p>
                        <div className="button-container">
                            <button
                                onClick={() => (window.location = '/')}
                                className="btn from-left bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                返回貼文頁面
                            </button>
                        </div>
                    </>
                )}
            </Layout>
        </div>
    );

}

export default Createpost;
