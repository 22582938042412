//user-preview.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './test.css'; // You may not need this if you're using Tailwind for everything
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdPreview, MdCatalog } from 'md-editor-rt';
import 'md-editor-rt/lib/preview.css';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import '../views/css/post.css'
import './PostList'
import PostList from './PostList';
const UserPreview = () => {
    const [isLogging, setisLogging] = useState(false);
    const [avatarURL, setAvatarURL] = useState('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
    const [userID, setUserID] = useState('');
    const [User, setUser] = useState(''); // 初始化为空字符串
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true'); // Assuming you have a dark mode state
    const [userbio, setUserbio] = useState('');
    const [icons, setIcons] = useState(['faFilter']); // 添加图标状态
    useEffect(() => {
        const fetchUserData = async () => {
            const params = new URLSearchParams(window.location.search);
            const userID = params.get('userID');


            if (userID) {
                try {
                    const response = await axios.get(`https://backend.itsanonyanony.com/api/user/@${userID}?token=MadeByChenWeiHao`);
                    if (response.status === 200) {
                        setisLogging(true);
                        setUser(response.data);
                        getUserAvatar(response.data.UserID);
                    } else {
                        setisLogging(false);
                        console.log('err');
                    }
                } catch (error) {
                    console.error(':', error);
                    setisLogging(false);
                }
            } else {
                setisLogging(false);
                console.log('Missing userID or token');
            }
        };

        fetchUserData();
    }, []);


    useEffect(() => {
        const handleThemeChange = () => {
            setDarkMode(localStorage.getItem('theme') === 'true');
        };

        // 訂閱主題變更事件
        document.addEventListener('themeChange', handleThemeChange);

        return () => {
            // 清除訂閱
            document.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);
    useEffect(() => {
        if (User.bio) {
            const formattedBio = User.bio.replace('<br>', '\n');
            setUserbio(formattedBio);
        }
    }, [User.bio]);
    const getUserAvatar = async (UserID) => {
        try {
            const backendURL = `https://backend.itsanonyanony.com/api/getUserAvatar?ID=${UserID}`;
            const response = await axios.get(backendURL, { responseType: 'json' });

            if (response.status === 200) {
                const avatarURL = response.data.avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png';
                setAvatarURL(avatarURL);
            } else {
                setAvatarURL('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
                console.log('Error fetching avatar');
            }
        } catch (error) {
            console.error('An error occurred while fetching avatar:', error);
            setAvatarURL('');
        }
    };
    const containerStyle = {
        backgroundColor: isDarkMode ? '#1f2937' : '#f3f4f6',
        transition: 'background-color 0.3s ease-in-out',

    };
    return (
        <div className={`container mx-auto p-20 mb-10 border-b-2 ${isDarkMode ? 'dark' : 'border-white'}`}>

            <div className="flex items-start group">
                <div className="w-20 h-20 mb-5 overflow-hidden">
                    <img src={avatarURL} alt="Profile" className="w-full h-full object-cover rounded-full" />
                </div>
                <div className="ml-4 flex flex-col">
                    <h1 className={`text-2xl mb-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>{User.username}</h1>
                    <div style={{ width: '180px' }}>
                        <MdPreview
                            style={containerStyle}
                            modelValue={userbio}
                            theme={`${isDarkMode ? 'dark' : 'light'}`}
                            previewTheme='github'
                            className={`${isDarkMode ? 'dark-theme' : 'light-theme'}`} />
                    </div>

                </div>
            </div>

        </div>


    );
};

export default UserPreview;
