import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
const Registerboard = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [RE_password, setRE_password] = useState('');

    const error_toast = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const warn_toast = (message) => toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    const success_toast = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const handleRegister = async () => {

        if (password === RE_password) {
            if (/[?@!$A-Za-z0-9]/.test(password)) {

                const userData = {
                    username: username,
                    password: password,
                    badgeID: []
                }
                try {
                    const response = await axios.post('https://backend.itsanonyanony.com/api/createUser', userData);
                    if (response.data.message === 'replace') {
                        error_toast('使用者名稱重複')
                        setUsername('')
                    } else {
                        setUsername('')
                        setPassword('')
                        setRE_password('')
                        window.location = '/login'
                    }
                } catch (error) {
                    error_toast('帳號註冊失敗')
                    console.error('帳號註冊失敗', error);
                }
            } else {
                error_toast('密碼只能有數字，英文字，@,!,$而已')
            }
        } else {
            error_toast('密碼不一致')
        }





    };

    return (

        <div style={{ backgroundImage: 'url(https://backend.itsanonyanony.com/uploads/thumbnail/1699360048963.jpeg)', backgroundSize: 'cover' }}>
            <ToastContainer />
            <section>
                <div className="form-box">
                    <div className="form-value">
                        <h2>Register-創建</h2>
                        <div className="inputbox">
                            <ion-icon name="mail-outline"></ion-icon>
                            <input
                                id="username"
                                type="text"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <label id="username">使用者名稱</label>
                        </div>
                        <div className="inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input
                                id="password"
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <label id="userpsw">使用者密碼</label>
                        </div>
                        <div className="inputbox">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input
                                id="password"
                                type="password"
                                required
                                value={RE_password}
                                onChange={(e) => setRE_password(e.target.value)}
                            />
                            <label id="userpsw">再次確認密碼</label>
                        </div>

                        <button onClick={handleRegister} className="glow-on-hover">
                            Sign up
                        </button>

                        <div className="register">
                            <p>
                                有帳號了?? <a href="/login">登入!</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Registerboard;
