import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate }
  from 'react-router-dom';
import Main from './pages/main'
import Login from './pages/login';
import UserProfile from './pages/user-profile';
import Custom404 from './pages/404page';
import Snowfall from 'react-snowfall'
import Gift from './pages/gift';
import Register from './pages/register';
import Post from './pages/post';
import Createpost from './pages/createpost';
import React, { useState, useEffect } from 'react';
import Setting from './pages/setting';
function App() {
  const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');

  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'true');
    };

    // Subscribe to the theme change event
    document.addEventListener('themeChange', handleThemeChange);

    return () => {
      // Unsubscribe when the component is unmounted
      document.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);
  const containerStyle = {

    flexDirection: 'row', // Horizontal layout
    padding: 0,
    backgroundColor: isDarkMode ? '#1f2937' : '#f3f4f6',
    transition: 'background-color 0.3s ease-in-out',
  };
  return (
    <div style={containerStyle}>
      {
        //<Snowfall snowflakeCount={50} />
      }
      <Router>
        <Routes>
          <Route exact path='/' element={<Main />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/user-profile' element={<UserProfile />} />
          <Route exact path='/gift' element={<Gift />} />
          <Route path="*" element={<Custom404 />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/post' element={<Post />} />
          <Route exact path='/createpost' element={<Createpost />} />
          <Route path='/setting' element={<Setting />}></Route>
        </Routes>
      </Router>
    </div>

  );
}

export default App;
