import React, { useState, useEffect } from 'react';

import PostList from '../views/PostList.jsx';
import Layout from '../views/Layout.jsx';

const Main = () => {
    const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');

    useEffect(() => {
        const handleThemeChange = () => {
            setDarkMode(localStorage.getItem('theme') === 'true');
        };

        // Subscribe to the theme change event
        document.addEventListener('themeChange', handleThemeChange);

        return () => {
            // Unsubscribe when the component is unmounted
            document.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    const containerStyle = {

        flexDirection: 'row', // Horizontal layout
        padding: 0,
        backgroundColor: isDarkMode ? '#1f2937' : '#f3f4f6',
        transition: 'background-color 0.3s ease-in-out',
    };



    return (
        <div style={containerStyle}>
            <Layout>
            <PostList />
            
            </Layout>


        </div>
    );
}

export default Main;
