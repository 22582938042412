//Postlist.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/button2.css';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

const PostList = ({ userID }) => {
  const [posts, setPosts] = useState([]);
  const [noPosts, setNoPosts] = useState(true);
  const [un, setUn] = useState('');
  const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');
  const [selectedTag, setSelectedTag] = useState(null);
  const [isAdmin, setisAdmin] = useState(false)
  const [logginguserID, setlogginguserID] = useState('')
  const error_toast = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});
const warn_toast = (message) => toast.warn(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});
const success_toast = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
});
  useEffect(() => {

    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
          if (response.data.data === 'success') {
            setisAdmin(response.data.user.isAdmin)
            setlogginguserID(response.data.user.UserID)

          } else {
            setisAdmin(false);
            console.log('err');
          }
        } catch (error) {
          console.error('驗證令牌时发生错误:', error);
          setisAdmin(false);
        }
      } else {
        setisAdmin(false);
      }
    };

    // 调用异步函数以获取用户信息
    fetchUserData();
  }, []);
  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'true');
    };

    // 訂閱主題變更事件
    document.addEventListener('themeChange', handleThemeChange);

    return () => {
      // 清除訂閱
      document.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tagValue = urlParams.get('tag');

    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tagValue = urlParams.get('tag');

      try {
        let apiUrl = 'https://backend.itsanonyanony.com/api/posts';

        // 如果有標籤，將標籤添加到 API URL 中
        if (tagValue) {
          apiUrl += `?tag=${tagValue}`;
        }

        // 如果有使用者ID，將使用者ID添加到 API URL 中
        if (userID) {
          apiUrl += tagValue ? `&Username=${userID}` : `?Username=${userID}`;
          console.log(apiUrl);
        }

        const response = await axios.get(apiUrl);

        // 將貼文按照日期排序
        const sortedPosts = response.data.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });

        setPosts(sortedPosts);

        setNoPosts(sortedPosts.length === 0);
        setSelectedTag(tagValue);
      } catch (error) {
        console.error(':', error);
      }
    };


    fetchData();
  }, [userID]);

  const fetchData = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tagValue = urlParams.get('tag');

    try {
      let apiUrl = 'https://backend.itsanonyanony.com/api/posts';

      // 如果有標籤，將標籤添加到 API URL 中
      if (tagValue) {
        apiUrl += `?tag=${tagValue}`;
      }

      // 如果有使用者ID，將使用者ID添加到 API URL 中
      if (userID) {

        apiUrl += tagValue ? `&Username=${userID}` : `?Username=${userID}`;
        console.log(apiUrl)
      }

      const response = await axios.get(apiUrl);

      setPosts(response.data);
      setNoPosts(response.data.length === 0);
      setSelectedTag(tagValue);
    } catch (error) {
      console.error(':', error);
    }
  };
  const getUserName = async (authorID) => {
    if (authorID) {
      try {
        const response = await axios.get(`https://backend.itsanonyanony.com/api/username/${authorID}`);
        setUn(response.data.username);
      } catch (error) {
        console.error('获取用户名时出错:', error);
      }
    }
  };

  const handleTagClick = (tag, e) => {
    // Stop event propagation to prevent redirection
    e.stopPropagation();

    // Your existing logic for handling tag click
    window.location.href = `/?tag=${tag}`;
  };

  const goBack = () => {
    window.location.href = '/';
  };

  const submit = (postID) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(postID)
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
  };
  const handleDelete = (postID) => {
    const remvoedata = {
      postId: postID
    }
    try{
      axios.post('https://backend.itsanonyanony.com/api/delete', remvoedata)
      success_toast('刪除成功!')
      setTimeout(() => {
        window.location.href = '/'
      }, 1500);
    }catch(err){
      console.log(err)
      error_toast('刪除失敗..')
    }
    
    console.log(`Deleting post with ID: ${postID}`);
  };

  const handleEdit = (postID) => {

    console.log(`Editing post with ID: ${postID}`);
    window.location.href = `/createpost?editorID=${postID}`
  };

  const handleReport = (postID) => {
    // Implement report logic here
    console.log(`Reporting post with ID: ${postID}`);
  };

  return (

    <div style={{ transition: 'background-color 0.3s ease-in-out' }} className={`container mx-auto p-0 my-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
       <ToastContainer></ToastContainer>
      <div className="grid grid-cols-1 gap-4">
        {noPosts ? (
          <div className="text-center">
            <h1 style={{ fontSize: '42px' }}>看來這個人不是很想發文</h1>
            <button
              className={`mx-auto ${isDarkMode ? 'bg-blue text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={goBack}
            >
              返回
            </button>
          </div>
        ) : (
          posts.map((post) => (
            <div
              key={post.ID}
              className={`relative xl:w-full lg:max-w-full xl:max-w-screen-sm xl:w-custom rounded overflow-hidden shadow-lg ${isDarkMode ? 'bg-dark' : 'bg-light'}`}
              style={{ backgroundColor: isDarkMode ? '#333' : '#fff' }}
              onClick={(e) => {
                if (e.target.tagName !== 'A') {
                  window.location.href = `/post?postID=${post.ID}`;
                }
              }}
            >
              {fetchData}
              <img src={post.thumbnail} alt="文章图片" className="w-full h-48 object-cover" />
              <div className="px-6 py-4">
                <p className={`font-bold text-xl mb-2 ${isDarkMode ? 'text-blue' : 'text-gray-700'}`}>{post.title}</p>
                <p className={`text-base ${isDarkMode ? 'text-blue' : 'text-gray-700'}`}>
                  日期: {new Date(post.date).toLocaleString('zh-TW', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </p>
                <p className={`text-base ${isDarkMode ? 'text-blue' : 'text-gray-700'}`}>
                  作者: {un || post.author}
                  {post.tag && Array.isArray(post.tag) ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      {post.tag.map((tag, index) => (
                        <button
                          key={index}
                          onClick={(e) => handleTagClick(tag, e)}
                          className={`inline-block ${selectedTag === tag ? 'bg-blue text-white' : 'bg-gray-200 text-gray-700'
                            } rounded-full px-3 py-1 text-sm font-semibold mr-2`}
                        >
                          {tag}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div onClick={(e) => e.stopPropagation()}>
                      <button
                        onClick={(e) => handleTagClick(post.tag, e)}
                        className={`inline-block ${selectedTag === post.tag ? 'bg-blue text-white' : 'bg-gray-200 text-gray-700'
                          } rounded-full px-3 py-1 text-sm font-semibold mr-2`}
                      >
                        {post.tag}
                      </button>
                    </div>
                  )}
                </p>
              </div>
              <div className="absolute bottom-4 right-4 flex items-center" onClick={(e) => e.stopPropagation()}>
                {isAdmin ? (
                  // For Admin
                  <>
                    <div onClick={() => submit(post.ID)}>
                      <FontAwesomeIcon icon={faTrash} className="text-red-500 cursor-pointer mr-3" />
                    </div>
                    <div onClick={() => handleEdit(post.ID)}>
                      <FontAwesomeIcon icon={faEdit} className="text-blue-500 cursor-pointer mr-3" />
                    </div>

                  </>
                ) : (
                  logginguserID === post.userID && (
                    // For the post author
                    <>
                      <div onClick={() => handleDelete(post.ID)}>
                        <FontAwesomeIcon icon={faTrash} className="text-red-500 cursor-pointer mr-3" />
                      </div>
                      <div onClick={() => handleEdit(post.ID)}>
                        <FontAwesomeIcon icon={faEdit} className="text-blue-500 cursor-pointer mr-3" />
                      </div>
                    </>
                  )
                )}
                {

                }
                <div onClick={() => handleReport(post.ID)}>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 cursor-pointer" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PostList;