/* eslint-disable jsx-a11y/anchor-is-valid */
// Header.jsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import logo from '../../img/logo.png';
import axios from 'axios';


const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLogging, setisLoggin] = useState(false)
  const [avatarURL, setAvatarURL] = useState('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
  const [userID, setUserID] = useState('');
  const [User, setUser] = useState(''); // 初始化为空字符串
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const logout = async () => {
    const token = localStorage.getItem('token');
    try {
      localStorage.removeItem("token")
      window.location.href = '/'

      setisLoggin(false);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('theme', newDarkMode.toString());

    // 觸發自定義事件通知其他元件主題變更
    document.dispatchEvent(new Event('themeChange'));
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      // You can add additional logic here based on window width
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //check login
  useEffect(() => {

    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
          if (response.data.data === 'success') {
            setisLoggin(true);
            setUserID(response.data.user.UserID);
            setUser(response.data.user); // 更新User为用户对象
            getUserAvatar(response.data.user.UserID);
          } else {
            setisLoggin(false);
            console.log('err');
          }
        } catch (error) {
          console.error('驗證令牌时发生错误:', error);
          setisLoggin(false);
        }
      } else {
        setisLoggin(false);
      }
    };

    // 调用异步函数以获取用户信息
    fetchUserData();
  }, []);
  //獲取投向
  const getUserAvatar = async (UserID) => {
    try {
      const backendURL = `https://backend.itsanonyanony.com/api/getUserAvatar?ID=${UserID}`;
      const response = await axios.get(backendURL, { responseType: 'json' });

      if (response.status === 200) {
        const avatarURL = response.data.avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png';
        setAvatarURL(avatarURL);
      } else {
        setAvatarURL('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
        console.log('Error fetching avatar');
      }
    } catch (error) {
      console.error('An error occurred while fetching avatar:', error);
      setAvatarURL('');
    }
  };



  return (
    <div className={`flex flex-col lg:flex-row ${isMobile ? 'lg:overflow-hidden' : ''}`}>
      {isMobile && (
        <div className={`fixed left-0 top-0 h-full w-64 bg-gray-800 text-white transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0`} style={{ zIndex: 2 }}>
          <div className="p-4">
            <button className="p-2 text-white lg:block" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <a href="/" className="block py-2 text-white">主頁</a>
            <a href="/createpost" className={`block py-2 text-white`}>創建貼文</a>
            <a target='blank' href="https://discord.gg/fpj6trQg8x" className={`block py-2 text-white`} >
              <span style={{ marginRight: "10px" }}>Discord</span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
            {/* ... add more sidebar links */}
          </div>
        </div>
      )}

      <div className="flex-1">
        <div className={`flex items-center p-4 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-300'} ${isDarkMode ? 'text-white' : 'text-gray-dark'}`}>
          <div className="lg:hidden">
            <button className="p-2 text-white" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className={`mx-auto lg:mx-0 ${isSidebarOpen ? 'hidden lg:block' : 'lg:block'}`}>
            <img src={logo} alt="Logo" className="h-8" />
          </div>
          <div className="flex items-center ml-auto">
            <button className="p-2 text-white hidden lg:none" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <a href="/" className={`hidden lg:block mr-4 `}>主頁</a>
            <a href="/createpost" className={`hidden lg:block mr-4`}>創建貼文</a>
            <a target='blank' href="https://discord.gg/fpj6trQg8x" className={`hidden lg:block mr-4`} >
              <span style={{ marginRight: "10px" }}>Discord</span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>

            {isLogging ? (
              <>
                <img
                  src={avatarURL}
                  alt="Avatar"
                  className="h-8 rounded-full cursor-pointer"
                  onClick={toggleDropdown}
                  style={{ objectFit: 'cover', height: '40px', width: '40px' }} // 添加這一行
                />
                {isDropdownOpen && (
                  <div className="absolute right-10 z-50 top-20 mt-2 p-2 bg-white rounded shadow">
                    <a href={`/user-profile?userID=${userID}`} className="block px-4 py-2 text-gray-800">個人檔案</a>
                    <a href="/gift" className="block px-4 py-2 text-gray-800">稅換</a>
                    <a href="/setting" className="block px-4 py-2 text-gray-800">設定</a>
                    <button style={{ color: 'red' }} onClick={logout} className="block px-4 py-2 text-gray-800"><strong>登出</strong></button>

                  </div>
                )}
              </>
            ) : (
              <a href="/login" className="ml-4 p-2 rounded bg-gray-800 text-white">登入</a>
            )}

            <button className="ml-4 p-2 rounded" onClick={toggleDarkMode}>
              <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
