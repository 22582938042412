import React, { useState, useEffect } from 'react';
import Layout from '../views/Layout';
import PostList from '../views/PostList';
import axios from 'axios';
import UserPreview from '../views/user-preview';
const UserProfile = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLogging, setisLoggin] = useState(false)
    const [avatarURL, setAvatarURL] = useState('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
    const [userID, setUserID] = useState('');
    const [User, setUser] = useState(''); // 初始化为空字符串

    useEffect(() => {
        const fetchUserData = async () => {
          const params = new URLSearchParams(window.location.search);
          const userID = params.get('userID');

    
          if (userID) {
            try {
              const response = await axios.get(`https://backend.itsanonyanony.com/api/user/@${userID}?token=MadeByChenWeiHao`);
              if (response.status === 200) {
               
                setUser(response.data);
                getUserAvatar(response.data.UserID);
              } else {
               
                console.log('err');
              }
            } catch (error) {
              console.error(':', error);
          
            }
          } else {

            console.log('Missing userID or token');
          }
        };
    
        fetchUserData();
      }, []);
    //獲取投向
    const getUserAvatar = async (UserID) => {
        try {
            const backendURL = `https://backend.itsanonyanony.com/api/getUserAvatar?ID=${UserID}`;
            const response = await axios.get(backendURL, { responseType: 'json' });

            if (response.status === 200) {
                const avatarURL = response.data.avatar || 'https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png';
                setAvatarURL(avatarURL);
            } else {
                setAvatarURL('https://backend.itsanonyanony.com/uploads/avatars/default-avatar.png');
                console.log('Error fetching avatar');
            }
        } catch (error) {
            console.error('An error occurred while fetching avatar:', error);
            setAvatarURL('');
        }
    };

    return (
        <div>

            <Layout>
                <div className="flex space-x-8">
                    <div className="flex-1">
                        <UserPreview></UserPreview>
                        <PostList userID={User.username}></PostList>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default UserProfile;
