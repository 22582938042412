// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messagecenter {
    margin-left: 20%;
    margin-top: 10%;
    margin-right: 5%;
    border-bottom: 2px solid white;
}

.ErrorMessage {
    font-size: 52px;
    color: white;
}

.ErrorDec{
    color: rgb(175, 175, 175);

}
.ErrorDec a {
    color: rgb(255, 0, 0);
}`, "",{"version":3,"sources":["webpack://./src/views/css/404.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,yBAAyB;;AAE7B;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".messagecenter {\r\n    margin-left: 20%;\r\n    margin-top: 10%;\r\n    margin-right: 5%;\r\n    border-bottom: 2px solid white;\r\n}\r\n\r\n.ErrorMessage {\r\n    font-size: 52px;\r\n    color: white;\r\n}\r\n\r\n.ErrorDec{\r\n    color: rgb(175, 175, 175);\r\n\r\n}\r\n.ErrorDec a {\r\n    color: rgb(255, 0, 0);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
