// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center{
    margin-top: 10%;
}
.text-blue {
    color: white;
}
.text-gray-700{
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/css/gift.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":[".center{\r\n    margin-top: 10%;\r\n}\r\n.text-blue {\r\n    color: white;\r\n}\r\n.text-gray-700{\r\n    color: black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
