import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../views/Layout';
import logo from '../img/logo.png';
import '../views/css/button.css'
import '../views/css/gift.css'
import axios from 'axios';
const Gift = () => {
  const error_toast = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  const warn_toast = (message) => toast.warn(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  const success_toast = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const [isDarkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'true');
  const [code, setcode] = useState('')
  const [userID, setUserID] = useState('');
  const [User, setUser] = useState(''); // 初始化为空字符串
  const [isLogging, setisLoggin] = useState(false)
  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'true');
    };

    // 訂閱主題變更事件
    document.addEventListener('themeChange', handleThemeChange);

    return () => {
      // 清除訂閱
      document.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const confirm = async () => {
    const response = await axios.get(`https://backend.itsanonyanony.com/api/gift?code=${code}&userID=${userID}`, { responseType: 'json' })
    if (response.data.message === 'success') {
      success_toast('稅換成功!!')
    } else if (response.data.message === 'you have') {
      warn_toast('你已經擁有這個獎勵了，不要貪心')
    }else if(response.data.message === 'wtf UUID'){
      error_toast('未知的稅換代碼💀')
    }

  }



  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const response = await axios.post('https://backend.itsanonyanony.com/api/verifyJWT', { token });
          if (response.data.data === 'success') {
            setisLoggin(true);
            setUserID(response.data.user.UserID);
            setUser(response.data.user);
          } else {
            setisLoggin(false);
            console.log('err');
          }
        } catch (error) {
          console.error('驗證令牌时发生错误:', error);
          setisLoggin(false);
        }
      } else {
        setisLoggin(false);
        // 如果沒有登入，導向登入頁面
        window.location.href = '/login'
      }
    };

    fetchUserData();
  }, []);
  return (
    <div>
      <Layout>
        <ToastContainer />
        <div className='center'>
          <div className="flex flex-col items-center justify-center min-h-screen mt-[-18rem]">
            <img src={logo} alt="Logo" className="mx-auto mb-4 h-24 w-auto" />
            <h1 style={{ fontSize: '42px' }} className={`${isDarkMode ? 'text-blue' : 'text-gray-700'}`}>稅換你的禮物碼</h1>
            <input
              type="text"
              placeholder="00000000-0000-0000-0000-000000000000"
              className="mx-auto p-3 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 w-full md:w-1/2 lg:w-1/3"
              onChange={(e) => setcode(e.target.value)}
            />
            <button onClick={confirm} className="btn from-left">稅換!</button>
          </div>
        </div>

      </Layout>
    </div>
  );
};

export default Gift;
