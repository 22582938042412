// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    transition: background-color 0.3s ease-in-out;
}

.left {
    float: left;
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 50px;
}

.avatar {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

#light-theme {
    background-color: #f3f4f6;
    color: #333333;
}

#dark-theme {
    background-color: #1f2937;
    color: #dcdcdc;
}

.bio {
    margin-top: 50px;
    height: 200px;
    border-radius: 10px;
    border: 1px solid gray;
}

.right {
    float: right;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 100px;
    border: 2px #333333 solid;
    padding: 50px;
    width: 70%;
}

.password {
    width: 70%;
    border: 2px #333333 solid;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/views/css/test.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;AACjD;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["* {\r\n    transition: background-color 0.3s ease-in-out;\r\n}\r\n\r\n.left {\r\n    float: left;\r\n    margin-top: 50px;\r\n    margin-left: 100px;\r\n    margin-right: 50px;\r\n}\r\n\r\n.avatar {\r\n    border-radius: 50%;\r\n    width: 200px;\r\n    height: 200px;\r\n}\r\n\r\n#light-theme {\r\n    background-color: #f3f4f6;\r\n    color: #333333;\r\n}\r\n\r\n#dark-theme {\r\n    background-color: #1f2937;\r\n    color: #dcdcdc;\r\n}\r\n\r\n.bio {\r\n    margin-top: 50px;\r\n    height: 200px;\r\n    border-radius: 10px;\r\n    border: 1px solid gray;\r\n}\r\n\r\n.right {\r\n    float: right;\r\n    margin-top: 50px;\r\n    margin-left: 50px;\r\n    margin-right: 100px;\r\n    border: 2px #333333 solid;\r\n    padding: 50px;\r\n    width: 70%;\r\n}\r\n\r\n.password {\r\n    width: 70%;\r\n    border: 2px #333333 solid;\r\n    margin-top: 50px;\r\n    margin-bottom: 50px;\r\n    height: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
