import '.././views/css/404.css'
import Layout from '../views/Layout';
const Custom404 = () => {
    return (
        <div>
            <Layout>
                <div className='messagecenter'>
                    <h1 className='ErrorMessage'>
                        404 - Page Not Found
                    </h1>
                    <p className='ErrorDec'>看來出了點錯誤...我們找不到這個頁面。<a href='/'>回首頁</a></p>
                </div>

            </Layout>

        </div>
    );
};

export default Custom404;
