import React, { useState } from 'react';
import './css/login.css';
import './css/wtf.css';
import { ToastContainer, toast } from 'react-toastify';
const Loginboard = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const error_toast = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  const warn_toast = (message) => toast.warn(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  const success_toast = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  const handleLogin = async () => {
    try {
      const response = await fetch('https://backend.itsanonyanony.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        if (token) {
          // Token is valid, save it to local storage and perform the redirect.
          localStorage.setItem('token', token);
          window.location.href = '/';
        } else {
          error_toast('出了點錯誤💀.');
        }
      } else {
        // Handle non-200 HTTP status codes, e.g., login failed.
        error_toast('帳號或密碼錯誤.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      error_toast('An error occurred during login.');
    }
  };

  return (
    <div style={{ backgroundImage: 'url(https://backend.itsanonyanony.com/uploads/thumbnail/1699360048963.jpeg)', backgroundSize: 'cover' }}>
      <ToastContainer />
      <section>
        <div className="form-box">
          <div className="form-value">
            <h2>Login-登入</h2>
            <div className="inputbox">
              <ion-icon name="mail-outline"></ion-icon>
              <input
                id="username"
                type="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <label id="username">使用者名稱</label>
            </div>
            <div className="inputbox">
              <ion-icon name="lock-closed-outline"></ion-icon>
              <input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label id="userpsw">使用者密碼</label>
            </div>

            <button onClick={handleLogin} className="glow-on-hover">
              Log in
            </button>

            <div className="register">
              <p>
                你沒有帳號?? <a href="/register">創建!</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loginboard;
