import React, { useState, useEffect } from 'react';
import Layout from '../views/Layout';
import Loginboard from '../views/loginboard';
const Login = () => {
    return (
        <div>
            <Layout>
                <Loginboard></Loginboard>
            </Layout>
        </div>
    );
}

export default Login;
